import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Heading, Card, Link, css, IconButton, Badge } from 'theme-ui'
import { FaCloudSunRain, FaSun, FaRegSnowflake } from 'react-icons/fa'
import CardList from '@components/CardList'
import translate from '@src/translate'

const styles = {
  wrapper: {
    textAlign: `center`
  },
  avatarWrapper: {
    mb: 4
  },
  flag: {
    marginLeft: `auto`,
    marginRight: `auto`
  },
  title: {
    color: `omegaDark`
  }
}


export default function BrandList({ brand, season }) {
  const data = useStaticQuery(graphql`
    query TyrePatterns {
      allTyrePattern {
        edges {
          node {
            pageUrl,
            brand
            pattern
            carType
            season
            childMdx {
              body
              frontmatter {
                excerpt
              }
            }
            image {
              childImageSharp {
                __typename
                ... on ImageSharp {
                  ImageSharp_small: gatsbyImageData(
                    width: 48
                    height: 48
                    layout: FIXED
                    transformOptions: {cropFocus: NORTH}
                    placeholder: TRACED_SVG
                    quality: 75
                  )
                  ImageSharp_regular: gatsbyImageData(
                    width: 130
                    height: 130
                    layout: FIXED
                    transformOptions: {cropFocus: NORTH}
                    placeholder: TRACED_SVG
                    quality: 80
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const results = [];

  data.allTyrePattern.edges.sort(function(a, b) {
    var textA = a.node.pattern.toUpperCase();
    var textB = b.node.pattern.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  }).filter(s => s.node.brand === brand && s.node.season === season.toUpperCase()).forEach(pattern => {
    let excerpt = (
      <div>
        {pattern.node.childMdx.frontmatter.excerpt}
        <Box>
          <IconButton variant='simple' role='presentation'>
            {
              {
                'summer': <FaSun />,
                'winter': <FaRegSnowflake />,
                'all_year': <FaCloudSunRain />,
              }[pattern.node.season.toLowerCase()]
            }
            {/*<Badge variant='circle' ml={-0} mt={-0}>Sommer</Badge>*/}
          </IconButton>
        </Box>
      </div>
    );
    results.push(
      {
        id: pattern.node.brand+pattern.node.pattern,
        slug: pattern.node.pageUrl,
        title: pattern.node.brand+" "+pattern.node.pattern,
        thumbnail: pattern.node.image == null ? null : pattern.node.image.childImageSharp,
        imageVariant: "regular",
        excerpt: excerpt,
        // category: {
        //   color: "#e9d8fd",
        //   name: "Advertising",
        //   slug: "/category/advertising/"
        // },
        featured: true,
      }
    );
  });

  return (
    <CardList
      title={ "Alle " + brand + " "+translate(season) }
      nodes={results}
      variant={['tyrepattern']} />
  );
}

