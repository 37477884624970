import React from 'react'
import { Box, Heading, Card, Link, css } from 'theme-ui'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import Section from '@components/Section'
import Avatar from '@components/Avatar'
import getImageVariant from '@components/utils/getImageVariant'


const styles = {
  wrapper: {
    textAlign: `center`
  },
  avatarWrapper: {
    mb: 4
  },
  flag: {
    marginLeft: `auto`,
    marginRight: `auto`
  },
  title: {
    color: `omegaDark`
  }
}

const TyreBrandCompact = ({ brand, country, logo, ...props }) => {
  if (!brand) return ''

  const flag = country.flag && getImageVariant(country.flag, "regular")

  return (
    <Section aside title='Dæk mærket ' {...props}>
      <Card variant='paper'>
        <Box sx={styles.wrapper}>
            <Box sx={styles.avatarWrapper}>
              <Avatar
                avatar={logo}
                alt={brand}
                withPattern
              />
            </Box>
            <Heading variant='h3'>{brand}</Heading>
            <Heading variant='h4' sx={styles.title}>{country.name}</Heading>
            <Img
              image={flag}
              alt={country.name}
              css={css(styles.flag)}
            />
        </Box>
      </Card>
    </Section>
  )
}

export default TyreBrandCompact
