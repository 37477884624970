import React from "react"
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@theme-ui/mdx'
import { Card as CardComponent } from 'theme-ui'
import Seo from '@widgets/Seo'
import Divider from '@components/Divider'
import TyreBrandCompact from './TyreBrandCompact'
import PageTitle from '@components/PageTitle'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PatternList from "@src/PatternList"

const ExternalLink = props => {
  if (props.href.includes('alupriser.dk') || props.href[0] === '/' || props.href[0] === '#') {
    return <a href={props.href}>{props.children}</a>
  }
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  )
}

const components = {
  a: ExternalLink,
  patterns: PatternList
}

export default function PageTemplate({
                                       data: page,
                                       brand,
                                       ...props
                                     }) {

  const { pageContext: { siteUrl } = {} } = props

  return (
    <Layout {...props}>
      <Seo {...props} title={props.pageContext.brand+" dæk"} siteUrl={siteUrl} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle header={props.pageContext.brand+" dæk"}  />
      </Stack>
      <Divider />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <CardComponent variant='paper'>
            <MDXProvider components={components}>
              <MDXRenderer>{props.pageContext.body}</MDXRenderer>
            </MDXProvider>
          </CardComponent>
        </Main>
        <Sidebar>
          <TyreBrandCompact brand={props.pageContext.brand}  country={props.pageContext.country} logo={props.pageContext.logo} omitTitle/>
        </Sidebar>
      </Stack>
    </Layout>
  )
}
